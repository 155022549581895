<template>
  <v-container>
    <CardUI>
      <template v-slot:header>
        <h4>Группы с курсом ОПД в этом семестре</h4>
      </template>
      <template v-slot:body>
        <div class="opd-groups">
          <v-progress-circular v-if="!groups.length"  class="mt-5 mx-auto d-block" color="primary"
                                indeterminate width="2"/>
          <div v-for="group in groups" :key="group.id"
               class="opd-groups--card mb-3"
               :class="{'opd-groups--card__mobile': $vuetify.breakpoint.smAndDown}">
            <div class="group-card__name">
              {{ group.name }} #{{ group.id }}
            </div>
            <div class="group-card__department">
              <div>
                {{ group.department.institute.name }}
              </div>
              <div>
                {{ group.department.name }}
              </div>
            </div>
            <div class="group-card__students">
              <div>
                <span
                    :style=" group.users.length/group.total_students < 1 ? 'padding: 5px; margin-bottom:10px; display:block;  border-radius: 5px;' : '' ">Студенты
                  в АИС ({{ group.users.length }} / {{ group.total_students }} чел) </span>
              </div>
              <div style="border-bottom: 1px solid #eaeaea;" v-for="user in group.users" :key="user.id">
                <div>
                  <v-avatar size="14">
                    <v-img :lazy-src="user.avatar_16" :src="user.avatar"></v-img>
                  </v-avatar>
                  <v-icon :title="user.active_projects.map((p) => p.title).join(', ')" color="green"
                          v-if="user.active_projects.length > 0" size="18">mdi-check
                  </v-icon>
                  <v-icon color="red" v-else size="18">mdi-close</v-icon>
                  {{ user.name }} <span
                    v-if="user.last_visit">({{ $moment.utc(user.last_visit).local().format("DD.MM.YYYY") }})</span>
                  <span v-else>(Не заходили)</span>

                  <!--                  <a :href="'#/projects/'+project.id" v-for="project in user.projects" :key="project.id">-->
                  <!--                    {{project.title}}-->
                  <!--                  </a>-->
                </div>
              </div>
              <div>
                <span>C проектами {{ group.users.filter((u) => u.active_projects.length > 0).length }} / {{ group.total_students }}</span>
              </div>
            </div>
            <v-spacer/>
            <div>
              <v-autocomplete @change="setCurator(group.id, $event)" :readonly="!$store.getters.checkPermission('can_manage_pools')" :clearable="$store.getters.checkPermission('can_manage_pools')" item-value="id" v-model="group.pivot.curator_id" :items="$store.state.timetables.employees"
                              item-text="name" placeholder="Отвественный"></v-autocomplete>
            </div>
            <v-spacer/>
            <div>
              <v-btn :to="'/opd/group/'+group.id" color="primary" right small outlined>Подробнее</v-btn>
            </div>
          </div>

        </div>
      </template>
    </CardUI>
  </v-container>
</template>

<script>
import opd from "@/api/opd.js";
import CardUI from "@/components/UI/CardUI.vue";
import analytics from "@/api/analytics.js";

export default {
  name: "OpdComponent",
  components: {CardUI},
  data() {
    return {
      groups: []
    }
  },
  methods: {
    get_day_name(id) {
      return opd.get_day_name(id);
    },
    setCurator(group_id, curator_id){
      opd.changeCurator(group_id, curator_id);
    }
  },
  mounted() {
    if (!this.$store.getters.isAuth) {
      this.$router.push('/auth/login');
      return;
    }
    opd.groups().then((r) => {
      analytics.openOpdPage(this.$store.state.user.currentUser.id);
      this.groups = r.data;
    });
    this.$store.dispatch('getEmployees');
  }
}
</script>

<style scoped>


.group-card__name {
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.opd-groups--card {
  display: flex;
  font-size: 12px;
}

.opd-groups--card {
  padding: 10px;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}

.group-card__department {
  padding: 10px;
  font-size: 12px;
  width: 30%;
}

.group-card__calendar {
  font-size: 12px;
  width: 20%;
}

.group-card__students {
  font-size: 12px;
  margin: 0 20px;
}

.opd-groups--card__mobile {
  flex-direction: column;
  align-items: self-start;
}

.opd-groups--card__mobile > div {
  width: 100%;
  padding: 10px;
  margin: 0;
}

.opd-groups--card:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
