import endpoint from "@/api/endpoint";

export default {
    groups() {
        return window.axios.get(endpoint + '/api/opd/now/groups');
    },

    students(group_id) {
        return window.axios.get(endpoint + '/api/opd/now/groups/' + group_id + '/students');
    },

    changeCurator(group_id, curator_id) {
        return window.axios.post(endpoint + '/api/opd/now/groups/' + group_id + '/changeCurator', {curator_id});
    },

    goals() {
        return window.axios.get(endpoint + '/api/opd/now/goals');
    },

    get_day_name(id) {
        switch (id) {
            case -1: {
                return 'Не указано'
            }

            case 1: {
                return 'Понедельник'
            }
            case 2: {
                return 'Вторник'
            }
            case 3: {
                return 'Среда'
            }
            case 4: {
                return 'Четверг'
            }
            case 5: {
                return 'Пятница'
            }
            case 6: {
                return 'Суббота'
            }
        }
    },
}
